<template>
  <div>
    <ProjectExplorer />
  </div>
</template>

<script>
import ProjectExplorer from '../dashboard/ProjectExplorer.vue'

export default {
  name: 'Projects',
  components: {
    ProjectExplorer
  },
  methods: {
  }
}
</script>

<style>

</style>
