<template>
  <v-card>
    <v-card-title>Explore projects</v-card-title>
    <v-card-text>
      <v-form
        class="d-flex"
        @submit.prevent="fetchProjects"
      >
        <v-text-field
          v-model="query.title"
          label="Project search term"
        />
        <v-btn
          :disabled="query.title.length === 0"
          elevation="0"
          color="shamrock"
          :dark="query.title.length > 0"
        >
          Search
        </v-btn>
      </v-form>
      <v-list three-line>
        <v-list-item
          v-for="project in projects"
          :key="project._id"
          :to="`/projects/${project._id}`"
        >
          <v-list-item-content>
            <v-list-item-title>
              <strong>{{ project.title }}</strong> - {{ project.location.address }}
            </v-list-item-title>
            <v-list-item-subtitle>Created {{ (new Date(project.creationDate)).toISOString().slice(0,10) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ProjectExplorer',
  data () {
    return {
      projects: null,
      query: {
        title: ''
      }
    }
  },
  methods: {
    async fetchProjects () {
      const res = await this.$axios.get('/admin/projects', {
        params: this.query
      })
      this.projects = res.data.projects
    }
  }
}
</script>

<style>

</style>
